import React from "react"

import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"

import Navigation from "../components/Navigation"


const reseausocial = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="Plaiz - Le Réseau Social de la mode"/>
    <div>
    <h1 className="p1">Le Réseau Social Plaiz</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGame"><br/>
    <br/>
            <b><i> Un réseau social mobile privé pour les passionnés de mode, sans likes, sans followers, sans placement de produits. </i></b>
            <br/>
            <i></i><br/>
            <br/>L'application Plaiz te connecte avec les passionnés du même univers mode que toi.
            <br/>Si tu est fan de streetwear et de vintage, tu es connecté dès l'inscription, après un questionnaire, avec les fans de streetwear et de vintage
            <br/>
            <br/>Partage ton style à ceux qui partagent ton style
            <br/>
            <br/>Plus besoin de créer ta communauté et d'atteindre des années avant d'avoir une audience.
            <br/>
            <br/>
            <br/>Sur l'app, la seule personne à voir le nombre de réactions obtenues à une photo est la personne qui l'a publiée.
            <br/>Nous remettons en avant la créativité et l'expression personnelle, plutôt que la popularité ou la hype.
            <br/>
            <br/>Nous permettons à tous de découvrir les créateurs et professionnels de la mode qui n'ont pas encore buzzé ailleurs.
            <br/>
            <br/>Pour obtenir le mot de passe et accéder à la communauté, envoie nous un DM sur Instagram: <a target="#" href="https:instagram.com/plaiz_gang" className="textChangeGame"><b>@plaiz_gang</b></a>
            <br/>
            <br/>
            <br/>
            <br/>Télécharger l'app:
            <br/>
            <br/>
            <a target="#" href="https://apps.apple.com/fr/app/plaiz/id1262762421" className="textChangeGame"><b>- iOS</b></a><br/>
            <br/>
            <a target="#" href="https://play.google.com/store/apps/details?id=com.plaiz.appli&hl=fr" className="textChangeGame"><b>- Android</b></a><br/>
            <br/>
            <br/>
            <br/>
        </p>
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default reseausocial

const styles = {
  backToHomeLink: {
    color: "white",
  },
}